
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { getCookieConsentInfos } from './helpers'
import { CookieConsent as ReactCookieConsent } from "react-cookie-consent";

import "./CookieConsent.less"

const ReactCookieConsentStyles = {
    background: 'rgb(36 36 36 / 94%)',
    alignItems: 'center',
    paddingInline: 'clamp(0px, 5vw, 100px)',
}

const CookieConsent = () => {

    const cookieConsentInfos = useSelector(state => state.cookieConsentInfos.result)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getCookieConsentInfos())
    }, [dispatch])

    return (
        <ReactCookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="standards-cookieconsent"
            style={ReactCookieConsentStyles}
            expires={150}
            disableButtonStyles={true}
        >
            <div className="CookieConsentContainer">
                <div dangerouslySetInnerHTML={{ __html: cookieConsentInfos?.cookie_consent_configuration?.title }} />
                <div dangerouslySetInnerHTML={{ __html: cookieConsentInfos?.cookie_consent_configuration?.body }} />
            </div>
        </ReactCookieConsent>
    )
}

export default CookieConsent;