import React from 'react';
import { Table } from 'semantic-ui-react';


const Row = (title, field, isDate = false) => {

    if (!field) return

    let displayField = field


    if (isDate) {
        try {
            const [year, month, day] = field.split('-');
            const date = new Date(year, month - 1, day); // Month is zero-based

            if (!isNaN(date)) {
                displayField = date.toLocaleDateString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                });
            }
        } catch (e) { }
    }

    return (
        <Table.Row>
            <Table.Cell verticalAlign="top">
                {title}
            </Table.Cell>
            <Table.Cell verticalAlign="top">
                {displayField}
            </Table.Cell>
        </Table.Row>
    )
}


const TSPTypeStandard = (content) => {
    return (
        <Table>
            <Table.Body >
                {Row('ID:', content?.docid)}
                {Row('Type:', content?.type?.title)}
                {Row('Senior Line Manager:', content?.slm?.title)}
                {Row('Senior Line Manager Org:', content?.slmOrg?.title)}
                <Table.Row>
                    <Table.Cell>
                        OPI:
                    </Table.Cell>
                    <Table.Cell>
                        {content?.opi.map((opi) => <div> {opi?.title} </div>)}
                    </Table.Cell>
                </Table.Row>
                {Row('Preparing Activity:', content?.creators[0])}
                {Row('Status:', content?.status?.title)}
                {Row('Status comments:', content?.statusComments)}
                {Row('Approved Date:', content?.approvedDate, true)}
                {Row('Last Update:', content?.updatedDate, true)}
                {Row('DNFSB Interest:', content?.dnfsb?.title)}
                {Row('Invoked Standard:', content?.invokedstandard?.title)}
            </Table.Body>
        </Table>
    )
}

const TSPTypeProject = (content) => {
    return (
        <Table>
            <Table.Body >
                {Row('Project Registration Number:', content?.projectRegistrationNumber)}
                {Row('Project Status:', content?.projectStatus?.title)}
                {Row('Project Start Date:', content?.projectStartDate, true)}
                {Row('Senior Line Management Org:', content?.slmOrg?.title)}
                {Row('Senior Line Management Contact Name:', content?.slm?.title)}
                {Row('Document ID:', content?.documentID)}
                {Row('DNFSB Interest:', content?.dnfsb?.title)}
            </Table.Body>
        </Table>
    )
}

const TSPTypePerson = (content) => {
    return (
        <Table>
            <Table.Body >
                {Row('Role:', content?.personRole?.[0]?.title)}
                {Row('Status:', content?.personStatus?.title)}
                {Row('Site/organization:', content?.siteOrg)}
                {Row('Address:', content?.personAddress)}
                {Row('Phone:', content?.phone)}
                {Row('Mobile:', content?.mobile)}
                {Row('Email:', content?.email)}
                {Row('Notes:', content?.personNotes)}
            </Table.Body>
        </Table>
    )
}


const TSPTypeTableByType = ({ content }) => {

    const TSPType = content?.['@type'];

    if (TSPType === 'TSP.Type.standard')
        return TSPTypeStandard(content)
    else if (TSPType === 'TSP.Type.project')
        return TSPTypeProject(content)
    else if (TSPType === 'TSP.Type.person')
        return TSPTypePerson(content)

    return <></>
}

export default TSPTypeTableByType;