import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

import ClearAllIcon from '@mui/icons-material/ClearAll';

import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';


import { makeStyles, useTheme } from '@mui/styles';
import { SearchAppContext } from '@standards/context';

import FilterItemsCheckbox from './FilterItemsCheckbox';
import FilterItemsDate from './FilterItemsDate';
import { useLocalStorage } from '@standards/hooks';


const useStyles = makeStyles((theme) => ({
  list: {
    width: '100%',
    bgcolor: 'background.paper',
    overflow: "auto",
    [theme.breakpoints.down('md')]: {
      height: '100% !important',
    }
  },
}));

export default function TreeViewFilters({ filters, filtersNickNames, addRemoveFilterItem, facetUsedCounter, isFilterItemSelected, isLoading, isUpdate }) {

  const theme = useTheme()
  const classes = useStyles();

  const dispatch = React.useContext(SearchAppContext)
  // theme.palette.mode == "dark"
  const [expandAllChb, setExpandAllChb] = useLocalStorage("expandAllChb", false)

  let filtersState = {}

  // && Object.keys(filters).length > 0
  if (filters) {

    Object.keys(filters).map((filter) => {
      filtersState = { ...filtersState, [filter]: expandAllChb }
    })

  }

  const [filtersToggle, setFiltersToggle] = React.useState(filtersState);

  const [listTreeFiltersRect, setListTreeFiltersRect] = React.useState(0)
  const listTreeFiltersRef = React.useCallback(node => {
    if (node !== null) {
      setListTreeFiltersRect(node.getBoundingClientRect());
    }
  }, []);

  const getDynamicListTreeFiltersRefHeight = () => {
    return listTreeFiltersRect?.top ? window.pageYOffset + listTreeFiltersRect.top : 0
  }


  const handleClick = (name) => {
    setFiltersToggle(
      prevState => ({
        ...prevState,
        [name]: !filtersToggle[name]
      }))
  };

  const ChangeFilterToggle = (state) => {
    Object.keys(filters).map((filter) => {
      filtersState = { ...filtersState, [filter]: state }
    })

    setFiltersToggle(filtersState)
    setExpandAllChb(state)
  }

  const renderFilterItems = (filter) => {

    let element = ""
    let OperatorType = filtersNickNames ? filtersNickNames.find(f => f.field === filter).type : ""
    let type = filtersNickNames ? filtersNickNames.find(f => f.field === filter).widgetType : ""

    switch (type) {


      case "date":
        element = <FilterItemsDate
          name={filter}
          addRemoveFilterItem={addRemoveFilterItem}

        />
        break

      case "":
      case "checkbox":
        element = < FilterItemsCheckbox
          key={filter}
          name={filter}
          items={filters[filter]}
          addRemoveFilterItem={addRemoveFilterItem}
          isFilterItemSelected={isFilterItemSelected}
          isUpdate={isUpdate}
          type={OperatorType}
        />
        break
    }

    return element
  }

  return (
    <List
      ref={listTreeFiltersRef}
      sx={{ height: `calc(100vh - ${parseInt(getDynamicListTreeFiltersRefHeight())}px)` }}
      className={classes.list}
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={
        <>
          <ListSubheader component="div" id="nested-list-subheader">
            <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "11px" }}>
              <div style={{ display: 'flex', alignItems: 'center', lineHeight: '0px' }}>


                <Button
                  style={{ padding: "0px", fontSize: "small", paddingRight: "5px" }}
                  onClick={() => dispatch({ type: "RESET", function: "ALL" })}
                >
                  {<ClearAllIcon fontSize="large" />} Reset Search
                </Button>

              </div>

              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    onClick={(e) => ChangeFilterToggle(e.target.checked)}
                    checked={expandAllChb}
                  // checked={}
                  />
                }
                label={<span style={{ fontSize: "0.857rem", display: 'flex' }}>EXPAND ALL</span>}
              />

            </div>
            <Divider style={{ paddingTop: "18px" }} />
          </ListSubheader>
        </>
      }
    >

      {filters &&
        Object.keys(filters).map((filter) => {
          let activeFiltersCounter = 0
          let primaryLabel, primaryWidgetType = ""
          try {
            activeFiltersCounter = (facetUsedCounter && facetUsedCounter[filter]) ? facetUsedCounter[filter][1].length : 0
            primaryLabel = filtersNickNames ? filtersNickNames.find(f => f.field === filter).alias : ""
          }
          catch (err) {
            console.error(err)
          }

          return (
            <div key={filter}>
              <ListItemButton
                onClick={() => handleClick(filter)}
                disabled={isUpdate}
                style={{ margin: "10px", background: "#eceff1", padding: "12px", borderTopRightRadius: "15px" }}
              >

                {/* 

                REQUESTED REMOVAL 
                <ListItemIcon>
                  <Badge badgeContent={activeFiltersCounter} color="primary">
                    <FilterAltIcon color="action"/>
                  </Badge>
                </ListItemIcon> 
                
                */}


                <Typography variant='body1'>{primaryLabel}</Typography>
                {filtersToggle[filter] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={filtersToggle[filter]} timeout="auto" unmountOnExit>
                {
                  renderFilterItems(filter)
                }
              </Collapse>
              <Divider variant="middle" />
            </div>
          )
        })
      }
    </List>
  );
}